var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "edit-template-assignments-modal",
    attrs: { size: "lg", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" Bulk Template Assignment Edit "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-body" }, [
              _c("form", [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", {}, [_vm._v("Template")]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedGradeTemplateId,
                          expression: "selectedGradeTemplateId",
                        },
                      ],
                      staticClass: "custom-select form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedGradeTemplateId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.gradeTemplates, function (template, idx) {
                      return _c(
                        "option",
                        {
                          key: `template_${idx}_${template.gradeTemplateId}`,
                          domProps: {
                            value: template.gradeTemplateId,
                            selected:
                              _vm.selectedGradeTemplateId ==
                              template.gradeTemplateId,
                          },
                        },
                        [_vm._v(" " + _vm._s(template.gradeTemplateName) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-primary alignment pull-right",
                class: {
                  "kt-spinner kt-spinner--sm kt-spinner--light":
                    _vm.savingBulkEdit,
                },
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  disabled: _vm.savingBulkEdit || !_vm.selectedGradeTemplateId,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.save()
                  },
                },
              },
              [
                !_vm.savingBulkEdit
                  ? _c("i", { staticClass: "fa fa-save" })
                  : _vm._e(),
                _vm._v(
                  " " +
                    _vm._s(_vm.savingBulkEdit ? "Saving..." : "Save Changes") +
                    " "
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }