<template>
<div>
    <div
        id="kt_subheader"
        class="kt-subheader kt-grid__item"
    >
        <div class="kt-container kt-container--fluid pt-2">
            <div class="kt-subheader__main">
                <h3
                    v-if="deviceType !== 'mobile' && deviceType !== 'tablet'"
                    class="kt-subheader__title"
                >
                    Template Assign
                </h3>
                <div class="kt-input-icon kt-input-icon--right kt-subheader__search mt-1">
                    <input
                        v-model="searchString"
                        type="text"
                        class="form-control"
                        placeholder="Filter..."
                        @keyup="currentPage = 1"
                    >
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                            <i class="flaticon2-search-1" />
                        </span>
                    </span>
                </div>
            </div>

            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper mt-1">
                    <div class="paginator-container">
                        <div class="kt-pagination kt-pagination--brand">
                            <ul class="kt-pagination__links">
                                <li class="kt-pagination__link">
                                    <a
                                        v-if="deviceType !== 'mobile' && false"
                                        href="#"
                                        @click.stop.prevent="showOptionsDropDown = !showOptionsDropDown"
                                    >
                                        <i class="flaticon2-settings kt-font-brand" />
                                    </a>
                                    <div
                                        :class="{ show: showOptionsDropDown }"
                                        class="dropdown-menu"
                                    >
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                        > Show Teachers Only </a>

                                        <a
                                            class="dropdown-item"
                                            href="#"
                                        > Show Students Only </a>
                                    </div>
                                </li>
                                <li class="kt-pagination__link">
                                    <button
                                        href="#"
                                        class="btn btn-primary m-0"
                                        :disabled="bulkSelection.length == 0"
                                        @click.stop.prevent="showBulkEdition"
                                    >
                                        <i class="flaticon2-settings" />
                                        Bulk Assign
                                    </button>
                                </li>
                                <li
                                    v-if="deviceType !== 'mobile'"
                                    class="kt-pagination__link--first"
                                >
                                    <a
                                        href="#"
                                        @click.stop.prevent="first"
                                    ><i class="fa fa-angle-double-left kt-font-brand" /></a>
                                </li>
                                <li class="kt-pagination__link--next">
                                    <a
                                        href="#"
                                        @click.stop.prevent="previous"
                                    ><i class="fa fa-angle-left kt-font-brand" /></a>
                                </li>

                                <li class="kt-pagination__link--prev">
                                    <a
                                        href="#"
                                        @click.stop.prevent="next"
                                    ><i class="fa fa-angle-right kt-font-brand" /></a>
                                </li>
                                <li
                                    v-if="deviceType !== 'mobile'"
                                    class="kt-pagination__link--last"
                                >
                                    <a
                                        href="#"
                                        @click.stop.prevent="last"
                                    ><i class="fa fa-angle-double-right kt-font-brand" /></a>
                                </li>
                            </ul>
                            <div
                                v-if="deviceType !== 'mobile'"
                                class="kt-pagination__toolbar"
                            >
                                <select
                                    v-model="perPage"
                                    class="form-control kt-font-brand"
                                    style="width: 60px"
                                >
                                    <option :value="25">
                                        25
                                    </option>
                                    <option :value="100">
                                        100
                                    </option>
                                    <option :value="250">
                                        250
                                    </option>
                                </select>

                                <div
                                    v-if="['laptop', 'desktop'].indexOf(deviceType) > -1"
                                    class="pagination__desc"
                                >
                                    {{ currentPage == 1 ? currentPage : (currentPage - 1) * perPage + 1 }}
                                    -
                                    {{ perPage * currentPage > totalRecords ? totalRecords : perPage * currentPage }}
                                    of
                                    {{ totalRecords }}
                                    courses
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        {{ externalDistrictId }} Grade Template Course Assignments
                    </h3>
                </div>
            </div>
            <div class="kt-portlet__body pt-3">
                <div class="table-responsive m-0">
                    <table
                        :key="key"
                        class="table table-hover"
                    >
                        <thead>
                            <tr>
                                <td class="check">
                                    <label class="kt-checkbox kt-checkbox--bold">
                                        <input
                                            v-model="selectAll"
                                            type="checkbox"
                                            value=""
                                            class="kt-checkable"
                                        >
                                        <span />
                                    </label>
                                </td>
                                <td>
                                    <span>Course</span>
                                </td>
                                <td>
                                    <span>Template</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <GradeTemplateAssignRow
                                v-for="(course, idx) in page"
                                :key="course.vueId"
                                :course="course"
                                :index="idx"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <GradeTemplatesAssignModal ref="edit-template-assignments-modal" />
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import { mapState } from 'vuex';
import async from 'async';
import * as network from '../../network';
import Types from '../../store/Types';
import courseMixins from '../../store/mixins/courseMixins';
import GradeTemplateAssignRow from './GradeTemplateAssignRow.vue';
import GradeTemplatesAssignModal from './GradeTemplatesAssignModal.vue';

export default Vue.extend({
    name: 'GradeTemplateAssign',
    components: {
        GradeTemplateAssignRow,
        GradeTemplatesAssignModal,
    },
    mixins: [courseMixins],
    data() {
        return {
            key: 0,
            perPage: 25,
            filter: '',
            saving: false,
            currentPage: 1,
            selectedCourses: [],
            searchString: '',
            optionsHovering: false,
            showOptionsDropDown: false,
            bulkState: 'ACTIVE',
            refreshing: false,
            refreshStatus: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            district: (state) => state.user.school.externalDistrictId,
            externalDistrictId: (state) => state.user.school.externalDistrictId,
            deviceType: (state) => state.deviceType,
            googleAccountId: (state) => state.user.googleAccountId,
            googleEmail: (state) => state.user.googleEmail,

            gradeTemplates: (state) => state.database.gradeTemplates,
            googleCourseGradeTemplates: (state) => state.database.googleCourseGradeTemplates,

            googleCourses: (state) => state.database.googleCourses,
            courseSections: (state) => state.database.courseSections,

            schoolTerms: (state) => state.database.schoolTerms,
            bulkSelection: (state) => state.configuration.templateAssignments.bulkSelection,
        }),
        toolbarRefreshClass() {
            if (!['mobile'].includes(this.deviceType)) {
                return 'pull-right';
            }
            return 'mt-3 mb-3';
        },
        toolbarContainerClass() {
            if (['mobile'].includes(this.deviceType)) {
                return 'col-12';
            }
            return 'col-6';
        },
        noData() {
            return Boolean((this.courses || []).length == 0);
        },
        totalRecords() {
            return this.courses.length;
        },
        page() {
            return this.courses.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
        },
        totalPages() {
            return Math.ceil(this.totalRecords / this.perPage);
        },
        rows() {
            return this.courses.length;
        },
        courses() {
            const v = this;
            let { searchString } = v;
            searchString = (searchString || '').toLowerCase();

            const arraySortOrder = function (a, b) {
                if (a.sort < b.sort) return -1;
                if (a.sort > b.sort) return 1;
                return 0;
            };
            const courseSections = this.$_courseMixins_getCourses();

            const output = courseSections
                .map((c) => {
                    const course = { ...c };
                    course.vueId = `courseSection_${c.courseSectionId}}_${this.key}`;
                    course.sort = `${c.extCourseSectionId}_${c.name}`;
                    const teacherSearch = course.teachers.map((t) => `${t.lastName} ${t.firstName}  ${t.schoolEmail} `);
                    course.search = `${c.extCourseSectionId} ${c.name} ${teacherSearch.join(' ')}`.toLowerCase();
                    return course;
                })
                .filter((t) => (searchString ? t.search.indexOf(searchString.toLowerCase()) > -1 : true))
                .sort(arraySortOrder);
            return output;
        },
        selectAll: {
            get() {
                const v = this;
                return v.courses.length == v.bulkSelection.length;
            },
            set() {
                const v = this;
                if (v.courses.length == v.bulkSelection.length) {
                    this.$store.commit(Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SELECTION, {
                        values: [],
                    });
                    return;
                }
                this.$store.commit(Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SELECTION, {
                    values: v.courses,
                });
            },
        },
    },
    watch: {
        googleCourses() {
            this.key += 1;
        },
        googleCourseGradeTemplates() {
            this.key += 1;
        },
    },
    methods: {
        isCourseSelected(course) {
            return this.selectedCourses.indexOf(course.googleCourseId) > -1;
        },
        setGoogleCourseSelectedState(course, selected) {
            const { googleCourseId } = course;
            const output = this.selectedCourses.filter((id) => id !== googleCourseId);
            if (selected) output.push(googleCourseId);
            this.selectedCourses = output;
        },
        courseEdit(googleCourseId) {
            const v = this;
            v.$refs.courseEdit.open(googleCourseId);
        },
        refreshCourses() {
            const v = this;
            if (this.saving) return;
            this.saving = true;

            const { schoolTermId, schoolId } = this.user.school;
            network.google.refreshGoogleCourseAccess({ url: { schoolTermId, schoolId } }, (err) => {
                if (err) v.showError('Error Refreshing');
                this.saving = false;
                v.$store.dispatch(Types.actions.REFRESH_GOOGLE_COURSES);
                // alert('whay');
                // i need to refresh local db here
            });
        },
        bulkConfirm(googleCourseState) {
            if (this.saving) return;
            const deleteMessage = 'Are you sure you want to delete the selected courses? \nThe course will be removed permantently in google classroom.\n\nThere is no undo!';
            if (googleCourseState == 'DELETED') {
                if (!window.confirm(deleteMessage)) return;
            }

            this.$store.commit('setLoading', true);
            this.saving = true;

            const selectedGoogleCourseIds = this.selectedCourses.slice();
            const v = this;
            const { user } = this;
            const { schoolId, schoolTermId } = user.school;
            async.eachOfLimit(selectedGoogleCourseIds, 3, (googleCourseId, idx, nextCourse) => {
                network.google.modifyGoogleCourse({ url: { schoolTermId, schoolId, googleCourseId }, body: { googleCourseState } }, (err) => {
                    if (err) {
                        window.console.log(err);
                        return nextCourse(`Error setting course state ${googleCourseState} for googleCourseId: ${googleCourseId}, reloading page`);
                    }
                    v.$store.commit(Types.mutations.EDIT_GOOGLE_COURSE_STATE, { googleCourseId, googleCourseState });
                    nextCourse();
                });
            }, (err) => {
                v.saving = false;
                this.$store.commit('setLoading', false);

                if (err) {
                    v.showError(err);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
                v.selectedCourses = [];
            });
        },
        first() {
            this.currentPage = 1;
        },
        last() {
            this.currentPage = this.totalPages;
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage -= 1;
            }
        },
        next() {
            if (this.currentPage < this.totalPages) {
                this.currentPage += 1;
            }
        },
        showBulkEdition() {
            this.$refs['edit-template-assignments-modal'].open();
        },
    },
});
</script>

<style scoped>
label.kt-checkbox {
    width: 100%;
}

tbody label.kt-checkbox span {
    margin-top: 10px;
}

.kt-checkbox.kt-checkbox--single {
    height: auto;
    margin-bottom: 0;
}

tbody td,
thead th {
    height: 60px;
    vertical-align: middle;
}

div.blockOverlay {
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    opacity: 0.05;
    cursor: wait;
    position: absolute;
}
div.blockElement {
    z-index: 1011;
    position: absolute;
    margin-left: 41%;
    top: 250px;
}

.footer-bar > .form-group {
    background: #fff;
    padding: 20px 60px 20px 60px;
    margin-bottom: 0px;
}

.footer-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #ebedf2;
    z-index: 98;
}

body.modal-open .footer-bar {
    display: none;
}

td.notLinked {
    white-space: nowrap;
    font-size: 1.2rem;
}

.control-width {
    min-width: 180px;
    width: 50%;
}
</style>
