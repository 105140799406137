<template>
<tr
    v-if="course"
    :key="`updateRow_${key}`"
>
    <td class="kt-table-cell kt-table-cell--name">
        <label class="kt-checkbox kt-checkbox--bold">
            <input
                v-model="courseSelected"
                type="checkbox"
                :value="course"
            >
            <span :class="`color${Math.floor((course.extCourseSectionId.charCodeAt(0) + index) % 10)}`" />
        </label>
    </td>
    <td
        class="kt-table-cell kt-table-cell--name"
    >
        <div class="media mt-1 mb-1">
            <div class="align-self-center ml-3 kt-media-group mr-3">
                <a
                    href="#"
                    class="course-section-badge"
                    :class="`color${Math.floor((course.extCourseSectionId.charCodeAt(0) + index) % 10)}`"
                    @click.stop.prevent="checkCourse(course)"
                >
                    {{ course.extCourseSectionId }}
                </a>
            </div>
            <div class="media-body ml-3">
                <h5
                    class="mt-0"
                    :class="deviceType == 'mobile' ? 'truncate' : ''"
                >
                    <router-link
                        class="kt-link kt-link--state kt-font-bold kt-link--primary"
                        :to="{ name: 'CourseInfo',
                               params: {
                                   extCourseSectionId: course.extCourseSectionId,
                               },
                        }"
                    >
                        <i
                            v-if="course.gradeTemplate"
                            class="flaticon2-correct kt-font-success mr-1"
                        />

                        {{ course.name }}
                    </router-link>
                </h5>

                <p class="mb-0">
                    <span class="text-muted">Select a grade template: </span>
                </p>
            </div>
        </div>
    </td>
    <td
        class="kt-table-cell kt-table-cell--controls"
    >
        <GradeTemplateCourseUpdater
            v-if="course.gradeTemplate"
            :course="course"
            :index="index"
        />
    </td>
</tr>
</template>

<script>
import { mapState } from 'vuex';
import GradeTemplateCourseUpdater from '../../components/GradeTemplateCourseUpdater.vue';
import Types from '../../store/Types';

export default {
    name: 'GradeTemplateAssignRow',
    components: {
        GradeTemplateCourseUpdater,
    },
    props: {
        course: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        ...mapState({
            externalDistrictId: (state) => state.user.school.externalDistrictId,
            showQuickPanel: (state) => state.quickPanel.open,
            deviceType: (state) => state.deviceType,
            bulkSelection: (state) => state.configuration.templateAssignments.bulkSelection,
            courseSectionGradeTemplates: (state) => state.database.courseSectionGradeTemplates,
        }),
        totalTeachers() {
            return (this.courseSection?.teachers || []).length;
        },
        teachers() {
            const output = (this.courseSection?.teachers || []);
            return output.slice(0, this.maxTeachers);
        },
        linked() {
            return Boolean(this.courseSection?.googleCourseId);
        },

        courseSelected: {
            get() {
                return this.bulkSelection;
            },
            set() {
                const v = this;

                const selected = this.bulkSelection.find(
                    (selection) => selection.extCourseSectionId == v.course.extCourseSectionId,
                );
                if (!selected) {
                    this.$store.commit(Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SELECTION, {
                        values: [...v.bulkSelection, v.course],
                    });
                    return;
                }
                this.$store.commit(Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SELECTION, {
                    values: v.bulkSelection.filter(
                        (selection) => selection.extCourseSectionId != v.course.extCourseSectionId,
                    ),
                });
            },
        },
    },
    watch: {
        courseSectionGradeTemplates() {
            this.key += 1;
        },
    },
    methods: {
        checkCourse(c) {
            const v = this;
            const course = c;

            const selected = this.bulkSelection.find(
                (selection) => selection.extCourseSectionId == course.extCourseSectionId,
            );
            if (!selected) {
                this.$store.commit(Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SELECTION, {
                    values: [...v.bulkSelection, course],
                });
                return;
            }
            this.$store.commit(Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SELECTION, {
                values: v.bulkSelection.filter(
                    (selection) => selection.extCourseSectionId != course.extCourseSectionId,
                ),
            });
        },
    },
};
</script>

<style scoped>
div.media-body {
    min-width: 200px;
}

td.no-top-border {
    border-top: none;
}
td.kt-table-cell {
    vertical-align: middle;
}

td.kt-table-cell--check span {
    width: 40px;
}

td.kt-table-cell--name div.media {
    min-width: 440px;
}

td.kt-table-cell--teachers div.kt-media-group {
    min-width: 280px;
}

td.kt-table-cell--linked-courses {
    min-width: 160px;
}

td.kt-table-cell--badge {
    width: 160px;
    text-align: center;
}

td.kt-table-cell--controls {
    min-width: 260px;
}
.course-name {
    overflow: clip;
    text-overflow: ellipsis;
}

.course-name a {
    white-space: nowrap;
    font-size: 1.2rem;
}

.truncate-title {
    line-height: 28px;
    height: 28px;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
    margin-top: 0;
    padding-top: 9px;
}

a.kt-link--state {
    line-height: 20px;
    height: 20px;
    overflow: hidden;
}
</style>
