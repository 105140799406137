<template>
<b-modal
    ref="edit-template-assignments-modal"
    size="lg"
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Bulk Template Assignment Edit
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>

    <template #default>
        <div class="modal-body">
            <form>
                <div class="form-group">
                    <label class="">Template</label>
                    <select
                        v-model="selectedGradeTemplateId"
                        class="custom-select form-control"
                    >
                        <option
                            v-for="(template, idx) in gradeTemplates"
                            :key="`template_${idx}_${template.gradeTemplateId}`"
                            :value="template.gradeTemplateId"
                            :selected="selectedGradeTemplateId == template.gradeTemplateId"
                        >
                            {{ template.gradeTemplateName }}
                        </option>
                    </select>
                </div>
            </form>
        </div>
    </template>
    <template #modal-footer>
        <button
            type="button"
            class="btn btn-primary alignment pull-right"
            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': savingBulkEdit}"
            data-dismiss="modal"
            :disabled="savingBulkEdit || !selectedGradeTemplateId"
            @click.stop.prevent="save()"
        >
            <i
                v-if="!savingBulkEdit"
                class="fa fa-save"
            />
            {{ savingBulkEdit ? "Saving..." : "Save Changes" }}
        </button>
    </template>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';
import Types from '../../store/Types';

export default {
    name: 'GradeTemplatesAssignModal',
    props: {},
    data() {
        return {
            selectedGradeTemplateId: 0,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            bulkSelection: (state) => state.configuration.templateAssignments.bulkSelection,
            savingBulkEdit: (state) => state.configuration.templateAssignments.savingBulkEdit,
            gradeTemplates: (state) => state.database.gradeTemplates,
        }),
    },
    methods: {
        open() {
            this.$refs['edit-template-assignments-modal'].show();
        },
        close() {
            this.$refs['edit-template-assignments-modal'].hide();
        },
        save() {
            if (this.savingBulkEdit || this.bulkSelection.length == 0) return;
            if (!this.selectedGradeTemplateId) return;

            this.$store.commit(Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SAVING, true);

            let { selectedGradeTemplateId } = this;

            const courseSectionGradeTemplates = this.bulkSelection.map((course) => ({
                courseSectionId: course.courseSectionId,
                gradeTemplateId: selectedGradeTemplateId,
            }));

            const { showError, close, $store } = this;
            const payload = {
                courseSectionGradeTemplates,
                callback(err) {
                    $store.commit(Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SAVING, false);
                    if (err) return showError(err);
                    selectedGradeTemplateId = 0;
                    close();
                },
            };
            this.$store.dispatch(Types.actions.EDIT_COURSE_TEMPLATE_ASSIGNMENTS, payload);
        },
    },
};
</script>
