var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.course
    ? _c("tr", { key: `updateRow_${_vm.key}` }, [
        _c("td", { staticClass: "kt-table-cell kt-table-cell--name" }, [
          _c("label", { staticClass: "kt-checkbox kt-checkbox--bold" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.courseSelected,
                  expression: "courseSelected",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                value: _vm.course,
                checked: Array.isArray(_vm.courseSelected)
                  ? _vm._i(_vm.courseSelected, _vm.course) > -1
                  : _vm.courseSelected,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.courseSelected,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.course,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.courseSelected = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.courseSelected = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.courseSelected = $$c
                  }
                },
              },
            }),
            _c("span", {
              class: `color${Math.floor(
                (_vm.course.extCourseSectionId.charCodeAt(0) + _vm.index) % 10
              )}`,
            }),
          ]),
        ]),
        _c("td", { staticClass: "kt-table-cell kt-table-cell--name" }, [
          _c("div", { staticClass: "media mt-1 mb-1" }, [
            _c(
              "div",
              { staticClass: "align-self-center ml-3 kt-media-group mr-3" },
              [
                _c(
                  "a",
                  {
                    staticClass: "course-section-badge",
                    class: `color${Math.floor(
                      (_vm.course.extCourseSectionId.charCodeAt(0) +
                        _vm.index) %
                        10
                    )}`,
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.checkCourse(_vm.course)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.course.extCourseSectionId) + " ")]
                ),
              ]
            ),
            _c("div", { staticClass: "media-body ml-3" }, [
              _c(
                "h5",
                {
                  staticClass: "mt-0",
                  class: _vm.deviceType == "mobile" ? "truncate" : "",
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "kt-link kt-link--state kt-font-bold kt-link--primary",
                      attrs: {
                        to: {
                          name: "CourseInfo",
                          params: {
                            extCourseSectionId: _vm.course.extCourseSectionId,
                          },
                        },
                      },
                    },
                    [
                      _vm.course.gradeTemplate
                        ? _c("i", {
                            staticClass:
                              "flaticon2-correct kt-font-success mr-1",
                          })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.course.name) + " "),
                    ]
                  ),
                ],
                1
              ),
              _vm._m(0),
            ]),
          ]),
        ]),
        _c(
          "td",
          { staticClass: "kt-table-cell kt-table-cell--controls" },
          [
            _vm.course.gradeTemplate
              ? _c("GradeTemplateCourseUpdater", {
                  attrs: { course: _vm.course, index: _vm.index },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-0" }, [
      _c("span", { staticClass: "text-muted" }, [
        _vm._v("Select a grade template: "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }